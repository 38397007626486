import axios, { type AxiosInstance } from "axios";
import { useToast } from "vue-toastification";
import { getAccessTokenWithRefresh } from "~/api/pharmacy.api";
import getHeader from "~/api/headers";

let instance: AxiosInstance;

export function createAxiosInstance() {
  const url = baseUrl();
  const header = getHeader();
  instance = axios.create({
    baseURL: url,
    headers: header,
  });
  instance.interceptors.request.use(
    (config) => {
      const token = useCookie("access_token").value;
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    function (response) {
      const toast = useToast();
      if (response.data.message) {
        toast.clear();
        toast.success(response.data.message);
      }
      return response;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const data = await getAccessTokenWithRefresh();
            const auth = data.token;
            useCookie("access_token").value = auth.access_token;
            useCookie("refresh_token").value = auth.refresh_token;
            instance.defaults.headers.common.Authorization = `${auth.token_type} ${auth.access_token}`;
            return instance(originalConfig);
          } catch (_error: any) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }

            return Promise.reject(_error);
          }
        }

        if (err.response.status === 403) {
          useCookie("access_token").value = null;
          useCookie("refresh_token").value = null;
        }
      }

      if (err.response.data) {
        const toast = useToast();
        const { message } = err.response.data;
        toast.clear();
        message && toast.error(message);
      }
      return Promise.reject(err);
    },
  );
}
export const client = {
  async get<T>(url: string) {
    const response = await instance.get<T>(url);

    return response.data;
  },

  async post<T>(url: string, data: any) {
    const response = await instance.post<T>(url, data);

    return response.data;
  },

  async patch<T>(url: string, data: any) {
    const response = await instance.patch<T>(url, data);

    return response.data;
  },

  async put<T>(url: string, data: any) {
    const response = await instance.put<T>(url, data);

    return response.data;
  },
};

export function baseUrl() {
  const runtimeConfig = useRuntimeConfig();
  const publicEnv = runtimeConfig.public;
  return `${publicEnv.API_URL}/pharmacy/v1`;
}
