import axios from "axios";
import { client } from "~/utils/axiosClient.util";
import type { Token } from "~/types";
import { disconnectSocket } from "~/socket";

const baseUrl = () => {
  const runtimeConfig = useRuntimeConfig();
  const publicEnv = runtimeConfig.public;
  return `${publicEnv.API_URL}/pharmacy/v1`;
};

export async function signIn(credentials: {
  email: string;
  password: string;
}): Promise<void> {
  const res = await client.post<{
    access_token: string;
    refresh_token: string;
    token_type: string;
  }>(`${baseUrl()}/signin`, credentials);

  useCookie("access_token").value = res?.access_token;
  useCookie("refresh_token").value = res?.refresh_token;
}
export async function getAccessTokenWithRefresh(): Promise<Token> {
  try {
    const refreshToken = `${useCookie("access_token").value}`;
    const response = await axios.get("/refresh", {
      baseURL: baseUrl(),
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    return response.data;
  } catch (error: any) {
    await useRouter().push("/orders");
    if (error.response?.data) {
      throw error.response.data;
    } else {
      throw new Error("An error occurred during refresh.");
    }
  }
}

export async function signOut(): Promise<void> {
  disconnectSocket();
  useCookie("access_token").value = null;
  useCookie("refresh_token").value = null;
  await useRouter().push("/signin");
}
