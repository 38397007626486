import io from "socket.io-client";
import type { Socket } from "socket.io-client";
import { loadPendingOrders } from "~/api/orders.api";

let socket: Socket;
// export let isInitialized: Ref<boolean> = ref(false)

export function init(): Promise<void> {
  const runtimeConfig = useRuntimeConfig();
  const publicEnv = runtimeConfig.public;
  const url = publicEnv.API_URL;

  return new Promise((resolve) => {
    const token = useCookie("access_token").value;
    socket = io(url, {
      auth: { token },
    });
    startDataListener();
    resolve();
  });
}

function startDataListener() {
  socket.on("newOrder", async () => {
    await loadPendingOrders();
  });
}

export function disconnectSocket() {
  socket.disconnect();
}
