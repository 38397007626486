import { default as indexBEnub4EOtuMeta } from "/vercel/path0/pages/dispense/index.vue?macro=true";
import { default as indexaGlvcu5ussMeta } from "/vercel/path0/pages/dispense/prescription/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invoicesOsO5xW78nEMeta } from "/vercel/path0/pages/invoices.vue?macro=true";
import { default as _91id_93tvDo5AKAx4Meta } from "/vercel/path0/pages/orders/[id].vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as signin0XCmIKo45IMeta } from "/vercel/path0/pages/signin.vue?macro=true";
export default [
  {
    name: "dispense",
    path: "/dispense",
    meta: indexBEnub4EOtuMeta || {},
    component: () => import("/vercel/path0/pages/dispense/index.vue").then(m => m.default || m)
  },
  {
    name: "dispense-prescription",
    path: "/dispense/prescription",
    meta: indexaGlvcu5ussMeta || {},
    component: () => import("/vercel/path0/pages/dispense/prescription/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/vercel/path0/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signin0XCmIKo45IMeta || {},
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  }
]