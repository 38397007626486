import Toast, { POSITION } from "vue-toastification";
import { ToastOptions } from "vue-toastification/src/types";
import "vue-toastification/dist/index.css";

const options: ToastOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 4000,
};

export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.use(Toast, options);
});
