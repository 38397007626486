import { defineStore } from "pinia";

export const useOrdersCountStore = defineStore({
  id: "ordersCount",
  state: () => ({
    pendingOrders: undefined as number | undefined,
  }),
  getters: {
    notification: (state) => {
      return !!state.pendingOrders && state.pendingOrders > 0;
    },
  },
  actions: {
    storePendingOrdersCount(count: number) {
      this.pendingOrders = count;
    },
  },
});
