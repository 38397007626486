<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
import { createAxiosInstance } from "~/utils/axiosClient.util";

createAxiosInstance();
</script>
