/* eslint-disable @typescript-eslint/naming-convention */
export enum OrderEvents {
  PENDING = "pending",
  PHARMACY_SEEN = "pharmacy-seen",
  PHARMACY_ACCEPTED = "pharmacy-accepted",
  PAID = "paid",
  IN_DELIVERY = "in-delivery",
  IN_TRANSIT = "in-transit",
  CANCELLED = "cancelled",
  DELIVERED = "delivered",
  CDF_ARRIVED = "fp10cdf-arrived",
  REFUNDED = "refunded",
  PRICE_CHANGED = "price-changed",
  CHANGES_REQUESTED = "changes-requested",
  MEDICATIONS_RECEIVED = "medications-received",
}
